<template>
    <card-table-search :items="borrowables" :fields="fields" :actions="actions" :title="title"
                       :custom-rendered="['imageExtension']" class="lh-40"
                       :exportColumns="fields.filter(item => item.key !== 'imageExtension')">
        <template #imageExtension="slotProps">
            <img :src="thumbnailSrc(slotProps.row.item, type)" alt="Image" class="thumbnail-object-image"/>
        </template>
    </card-table-search>
</template>

<script>
    const CardTableSearch = () => import('@/components/CardTableSearch');

    import {thumbnailSrc}          from '@/util/image';
    import {loadAndDisplayDetails} from '@/util/borrowable';

    export default {
        name: "BorrowableTableSearchQuickview",
        components: {CardTableSearch},
        props: {
            borrowables: {
                type: Array,
                required: true
            },
            title: {
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                fields: [
                    {
                        key: 'imageExtension',
                        label: 'Image',
                        sortable: false
                    },
                    {
                        key: 'nom',
                        label: 'Nom',
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_display',
                        color: () => 'secondary',
                        icon: 'eye',
                        tooltip: 'Détail',
                        handler: ({item}) => this.showDetail(item)
                    }
                ]
            }
        },
        methods: {
            thumbnailSrc,
            showDetail(item) {
                loadAndDisplayDetails(item, this.type)
                    .catch(() => this.$toaster.error('Impossible de charger le détail'));
            }
        }
    }
</script>

<style scoped>
    .thumbnail-object-image {
        height: 40px;
    }
</style>
